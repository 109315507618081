@import "~/styles/responsive.scss";

.footer_wrapper {
  padding: 2.5rem 0;
  background-color: var(--footer-bg);
  color: var(--footer-color);
  border-top: 1px solid var(--footer-border-color);

  .title {
    text-transform: uppercase;
    color: var(--footer-title-color, var(--footer-color));
  }

  .footer {
    display: flex;
    flex-direction: column;

    @include screen(lg) {
      gap: 2rem;
    }
  }

  .link_item {
    transition: var(--transition);

    // &:hover {
    //   // color: var(--primary);
    // }
  }

  .socials {
    // background: var(--footer-social-icon-color);
   
    width: 1.5rem;
    height: 1.5rem;
    transition: var(--transition);

    &.instagram {
      mask-image: url("/svgs/instagram.svg");
      mask-size: contain;
      mask-repeat: no-repeat;
    }

    &.tiktok {
      mask-image: url("/svgs/tiktok.svg");
      mask-size: contain;
      mask-repeat: no-repeat;
    }
    &.facebook{
      mask-image: url("/svgs/facebook.svg");
      mask-size: contain;
      mask-repeat: no-repeat;
    }

    // &:hover {
    //   background: var(--primary);
    // }
  }

  .copyright {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--footer-border-color);
    padding-top: 0.75rem;

    @include screen(lg) {
      gap: 2rem;
      
    }
  }

  .icon path {
    fill: var(--footer-color);
  }
}
